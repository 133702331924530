import React from 'react';
import Layout from '../../components/layout';
import Questions from '../../components/Questions';

const Venenleiden = ({ data, location, ...props }) => {
	return (
		<Layout location={location} title="Venenleiden" noSlider {...props}>
			<p>
				Unsere Beine werden mit sauerstoffhaltigem Blut über die
				Arterien versorgt und dieses als sauerstoffarmes Blut über die
				Venen zurück zum Herzen transportiert.
			</p>
			<p>
				In der Tiefe des Gewebes liegen die dickeren Gefäße, an der
				Hautoberfläche feinste kleine Gefäße von 1mm Durchmesser und
				viel kleiner. Dazwischen liegen Gefäße zwischen 1-3 mm Dicke.
			</p>
			<p>Erweiterte Venen der Beine können operativ entfernt werden.</p>
			<p>
				Die ästhetisch störenden sog. Besenreiser und retikuläre Venen,
				Durchmesser zwischen 1 und 3 mm, lassen eine Behandlung mit der
				Sklerosierungstherapie zu. Hierbei wird ein seit Jahrzehnten
				bewährtes Medikament in kleinen Mengen in die zu behandelnden
				Gefäße injiziert. Über eine dadurch ausgelöste kleine
				Gefäßentzündung verschließt sich das Gefäß. Daraufhin erfolgt
				der Abbau des verschlossenen, funktionsuntüchtigen Gefäßes.
			</p>
			<p>
				Alternativ ist für diese Behandlung auch ein Gefäßlaser zu
				verwenden, wobei die Sklerosierung mit der Injektionstechnik
				weltweit bevorzugt wird, aus unterschiedlichen Beweggründen
				heraus.
			</p>
			<p>
				Vor jeder Behandlung erfolgt eine dopplersonografische
				Untersuchung Ihres Venensystems der Beine und anschließend ein
				Gespräch über die Behandlungsmöglichkeiten.
			</p>

			<Questions title="Venenleiden" prefix="um" />
		</Layout>
	);
};

export default Venenleiden;
